<template>
	<div>
		<form class="form" v-if="!filter.id">
			<div class="form-group input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">{{$t("common.brand")}}</span>
				</div>
				<input class="form-control" name="manufacturer" v-model="manufacturer" type="text">
			</div>
			<div class="form-group input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">{{$t("stocks.barcode")}}</span>
				</div>
				<input class="form-control" name="barcode" v-model="barcode" type="text">
			</div>
			<div class="form-group input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">{{$t("stocks.range")}}</span>
				</div>
				<input class="form-control" name="name" v-model="name" type="text">
			</div>
			<div class="form-group input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">{{$t("stocks.color")}}</span>
				</div>
				<StockColorButton :editable="false" v-model="color"/>
			</div>
			<div class="form-group input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">{{$t("stocks.refcode")}}</span>
				</div>
				<input class="form-control" name="reference" v-model="reference" type="text">
			</div>
			<div class="form-group input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">{{$t("common.type")}}</span>
				</div>
				<StockTypeButton :editable="false" v-model="type"/>
			</div>
			<div class="form-group input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">{{$t("stocks.stocks")}}</span>
				</div>
				<popper
					class="dropdown d-block"
					trigger="clickToToggle"
					:options="{ placement: 'bottom' }"
					:visible-arrow="false"
					ref="stocksDropdown"
				>
					<div class="dropdown-menu">
						<div class="dropdown-choices">
							<div class="dropdown-item" v-on:click="stocks = '0'; $refs.stocksDropdown.doClose()" type="button">{{$t("stocks.sout")}}</div>
							<div class="dropdown-item" v-on:click="stocks = '5'; $refs.stocksDropdown.doClose()" type="button">{{$t("stocks.fiveless")}}</div>
							<div class="dropdown-item" v-on:click="stocks = '10'; $refs.stocksDropdown.doClose()" type="button">{{$t("stocks.tenless")}}</div>
							<div class="dropdown-item" v-on:click="stocks = ''; $refs.stocksDropdown.doClose()" type="button">{{$t("stocks.whatev")}}</div>
						</div>
					</div>
					<button
						slot="reference"
						aria-expanded="false" 
						aria-haspopup="true"
						class="btn btn-left dropdown-toggle "
						data-flip="false"
						data-toggle="dropdown"
						name="btn-stocks"
						type="button"
						value=""
					>
						<template v-if="stocks == '0'">{{$t("stocks.sout")}}</template>
						<template v-else-if="stocks == '5'">{{$t("stocks.fiveless")}}</template>
						<template v-else-if="stocks == '10'">{{$t("stocks.tenless")}}</template>
						<template v-else>{{$t("stocks.whatev")}}</template>
					</button>
				</popper>
			</div>
			<!--div class="break"></div-->
			<div id="create-filter-wrapper" :class="showCreateFilter ? ' visible' : ''">
				<button class="create-filter btn btn-primary" v-on:click="createFilterModal" type="button">{{$t("stocks.nfil")}}</button>
			</div>
		</form>
		<table class="mobile-table table-sticky">
			<thead>
				<tr>
					<th>{{$t("stocks.barcode")}}</th>
					<th class="type">{{$t("common.type")}}</th>
					<th class="ref">{{$t("stocks.refcode")}}</th>
					<th>{{$t("common.brand")}}</th>
					<th>{{$t("stocks.range")}}</th>
					<th>{{$t("stocks.color")}}</th>
					<th>{{$t("common.descript")}}</th>
					<th>{{$t("common.price")}}</th>
					<th>{{$t("stocks.stocks")}}<br/><span class="text-muted">{{$t("stocks.used")}}</span></th>
					<!--th>{{$t("common.op")}}</th-->
					<th>{{$t("stocks.cond")}}<br><span class="text-muted">{{$t("stocks.remain")}}</span></th>
					<th>{{$t("common.action")}}</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="stock in filter.stocks" :key="stock.id" v-if="searchStockMatches(stock)" onclick="$(this).toggleClass('active')">
					<td class="desktop max-width-1">{{ prettyBarCode(stock.barcode) }}</td>
					<td class="mobile nametab" :class="{ 'empty-col': !stock.name }">{{unescape(stock.name || "")}}</td>
					<td class="mobile" :class="{ 'empty-col': !stock.manufacturer }">{{unescape(stock.manufacturer || "")}}</td>
					<th class="mobile" :class="{ 'empty-col': !stock.type }">{{$t("common.type")}}</th>
					<td class="max-width-1" :class="{ 'empty-col': !stock.type }">{{unescape(stock.type || "")}}</td>
					<th class="mobile" :class="{ 'empty-col': !stock.barcode }">{{$t("stocks.barcode")}}</th>
					<td class="mobile" :class="{ 'empty-col': !stock.barcode }">{{prettyBarCode(stock.barcode) }}</td>
					<th class="mobile" :class="{ 'empty-col': !stock.reference }">{{$t("stocks.refcode")}}</th>
					<td class="max-width-1" :class="{ 'empty-col': !stock.reference }">{{stock.reference}}</td>
					<td class="desktop max-width-1">{{unescape(stock.manufacturer || "")}}</td>
					<th class="mobile">{{$t("stocks.range")}}</th>
					<td class="max-width-1" :class="{ 'empty-col': !stock.name }">{{unescape(stock.name || "")}}</td>
					<th class="mobile">{{$t("stocks.color")}}</th>
					<td class="max-width-1">{{unescape(stock.color || "")}}</td>
					<th class="mobile" :class="{ 'empty-col': !stock.description }">{{$t("common.descript")}}</th>
					<td class="description max-width-2" :class="{ 'empty-col': !stock.description }">{{unescape(stock.description || "")}}</td>
					<th class="mobile" :class="{ 'empty-col': !stock.price }">{{$t("common.price")}}</th>
					<td :class="{ 'empty-col': !stock.price }">{{ stock.price ? stock.price + "€" : ''}}</td>
					<th class="mobile">{{$t("stocks.stocks")}}</th>
					<td><b>{{stock.stocks || 0}}</b><br/><span class="text-muted">{{stock.total_used || 0}}</span></td>
					<th :class="{ 'empty-col': !(stock.current || []).filter((bar, i) => i < 2 && bar < 100).length }" class="mobile">{{$t("common.op")}}</th>
					<!--td>{{ ( stock.current || [] ).length}}</td-->
					<!--th class="mobile">{{$t("stocks.cond")}}<br><span class="text-muted">{{$t("stocks.remain")}}</span></th-->
					<td :class="{ 'empty-col': !(stock.current || []).filter((bar, i) => i < 2 && bar < 100).length }">
						<!--ul>
							<li>
								<div v-for = "bar in stock.current" class="progressbar">
									<progress id="progress_5e9dceb2" max="100" :value="bar"></progress>
									<label class="text-primary" data-format="%d%%">{{bar}}%</label>
								</div>
							</li>
						</ul-->
						<div class="bottles">
							<div v-for = "( bar, i ) in ( stock.current || [] )" v-if="i < 2 && bar < 100" class="bottle-wrapper">
								<div class="level">
									<div class="level-bar" :style="`height: ${bar}%`"></div>
								</div>
								<div class="level-value">{{ bar }}%</div>
								<div class="bottle-mask"/>
							</div>
							<div class="bottles-more" v-if="stock.current && stock.current.length > 2">
							...
							</div>
						</div>
					</td>
					<td class="mobile-100 actions">
						<button class="btn btn-mod btn-primary" v-on:click="editStock( stock )" type="button">
							<i class="fa fa-edit"></i>
						</button>
					</td>
				</tr>
				<tr class="mobile-count noarrow">
					<td>{{ $t('common.count') }} / <i class= "text-muted"> {{ $t('statistics.totalused') }}</i></td>
					<td><b>{{ filter.stocks.reduce( ( a, b ) => a + parseInt(b.stocks), 0 ) || 0 }}</b> / <i class="text-muted">{{ filter.stocks.reduce( ( a, b ) => a + ( b.total_used || 0 ), 0 ) }}</i></td>
				</tr>
				<tr id="add-row" class="mobile-expanded btn-plus noarrow" v-if="full">
					<td class="td-button mobile-100" colspan="12">
						<div class="bottom-row">
							<button id="add-stock" class="btn-add fas fa-plus btn btn-tertiary" v-on:click="editStock();" type="button"></button>
							<div class="desktop total">
								{{ $t('common.count') }} : <b>{{ filter.stocks.reduce( ( a, b ) => a + parseInt(b.stocks), 0 ) || 0 }}</b> / <i class="text-muted">{{ filter.stocks.reduce( ( a, b ) => a + ( b.total_used || 0 ), 0 ) }}</i>
							</div>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
		<div class="rules" v-if="!full">
			<button
				class="btn-checkable btn btn-quaternary"
				:class="{ 'btn-quaternary-checked': filterEditShow }"
				@click="filterEditShow = !filterEditShow"
				type="button"
			>
				<i
					class="fa"
					:class="{ 'fa-chevron-right': !filterEditShow, 'fa-chevron-down': filterEditShow }"
					style="padding-right: 0.75rem"
				/>
				{{$t("stocks.setfilter")}}
			</button>
			<StatusButton alert="modal" id="deletefilter" v-on:click="showDeleteFilterModal=$event" type="danger">{{$t("stocks.cancelfilt")}}</StatusButton>
			<form ref="form" class="filter-edit form" :class="{ visible: filterEditShow }" @submit.prevent="saveEditedFilter">
				<div class="form-group input-group">
					<div class="input-group-prepend">
						<span class="input-group-text">{{$t("common.brand")}}</span>
					</div>
					<input class="form-control" type="text" v-model="filterEditForm.manufacturer">
				</div>
				<div class="form-group input-group">
					<div class="input-group-prepend">
						<span class="input-group-text">{{$t("stocks.barcode")}}</span>
					</div>
					<input class="form-control" type="text" v-model="filterEditForm.barcode">
				</div>
				<div class="form-group input-group">
					<div class="input-group-prepend">
						<span class="input-group-text">{{$t("stocks.range")}}</span>
					</div>
					<input class="form-control" type="text" v-model="filterEditForm.name">
				</div>
				<div class="form-group input-group">
					<div class="input-group-prepend">
						<span class="input-group-text">{{$t("stocks.color")}}</span>
					</div>
					<StockColorButton :editable="false" v-model="filterEditForm.color"/>
				</div>
				<div class="form-group input-group">
					<div class="input-group-prepend">
						<span class="input-group-text">{{$t("stocks.refcode")}}</span>
					</div>
					<input class="form-control" type="text" v-model="filterEditForm.reference">
				</div>
				<div class="form-group input-group">
					<div class="input-group-prepend">
						<span class="input-group-text">{{$t("common.type")}}</span>
					</div>
					<StockTypeButton :editable="false" v-model="filterEditForm.type"/>
				</div>
				<div class="form-group input-group">
					<div class="input-group-prepend">
						<span class="input-group-text">{{$t("stocks.stocks")}}</span>
					</div>
					<popper
						class="dropdown d-block"
						trigger="clickToToggle"
						:options="{ placement: 'bottom' }"
						:visible-arrow="false"
					>
						<div class="dropdown-menu">
							<div class="dropdown-choices">
								<button class="dropdown-item" v-on:click="filterEditForm.stocks = '0'" type="button">{{$t("stocks.sout")}}</button>
								<button class="dropdown-item" v-on:click="filterEditForm.stocks = '5'" type="button">{{$t("stocks.fiveless")}}</button>
								<button class="dropdown-item" v-on:click="filterEditForm.stocks = '10'" type="button">{{$t("stocks.tenless")}}</button>
								<button class="dropdown-item" v-on:click="filterEditForm.stocks = ''" type="button">{{$t("stocks.whatev")}}</button>
							</div>
						</div>
						<button
							slot="reference"
							aria-expanded="false"
							aria-haspopup="true"
							class="btn btn-gray dropdown-toggle"
							data-flip="false" data-toggle="dropdown"
							name="btn-stocks"
							type="button"
							value=""
						>
							<template v-if="filterEditForm.stocks == '0'">{{$t("stocks.sout")}}</template>
							<template v-else-if="filterEditForm.stocks == '5'">{{$t("stocks.fiveless")}}</template>
							<template v-else-if="filterEditForm.stocks == '10'">{{$t("stocks.tenless")}}</template>
							<template v-else>{{$t("stocks.whatev")}}</template>
						</button>
					</popper>
				</div>
				<div class="form-group input-group">
					<div class="input-group-prepend">
						<span class="input-group-text">{{$t("common.name")}}</span>
					</div>
					<input class="form-control" type="text" v-model="filterEditForm.filterName">
				</div>
				<input class="btn btn-primary center save" type="submit" :value="$t('common.save')">
			</form>
		</div>
		<Modal v-if="showNewFilterModal" name="new-filter">
			<template v-slot:header>
				<h1 class="title-search">{{ $t('stocks.createfilter') }}</h1>
			</template>
			<template v-slot:content>
				<div>
					<h2>{{ $t('stocks.createfiltername') }}</h2>
					<input type="text" class="form-control" v-model="newFilterName"/>
				</div>
			</template>
			<template v-slot:buttons>
				<button id="apply" class="btn btn-primary" v-on:click="createFilter" type="button">{{ $t('common.validate') }}</button>
				<button id="cancel" class="btn btn-gray" type="button" v-on:click="newFilterName = ''; showNewFilterModal = false">{{ $t('common.cancel') }}</button>
			</template>
		</Modal>
		<EditStock v-if="showEditStockModal" :data="currentEditStock" v-on:close="showEditStockModal = false; currentEditStock = {}"/>
		<Modal v-if="showDeleteFilterModal" type="small">
			<template v-slot:header>
					<h1 class="title-search">{{ $t('common.del') }}</h1>
			</template>
			<template v-slot:content>
				<h2>{{ $t('stocks.deletefilter') }}</h2>
			</template>
			<template v-slot:buttons>
				<button id="apply" class="btn btn-primary" v-on:click="deleteFilter" type="button">{{ $t('common.validate') }}</button>
				<button id="cancel" class="btn btn-gray" type="button" v-on:click="showDeleteFilterModal(-1); showDeleteFilterModal = false">{{ $t('common.cancel') }}</button>
			</template>
		</Modal>
	</div>
</template>

<script>
	import unescapeJs from 'unescape-js'
	import Popper from 'vue-popperjs'
	import EditStock from '../../modals/EditStock.vue'
	import Modal from '../../components/Modal.vue'
	import StockTypeButton from '../../components/StockTypeButton.vue'
	import StatusButton from '../../components/StatusButton.vue'
	import StockColorButton from '../../components/StockColorButton.vue'
	import matches from "./stockMatches"

	export default {
		components: {
			Popper,
			Modal,
			EditStock,
			StockTypeButton,
			StockColorButton,
			StatusButton,
		},
		props: {
			filter: { type: Object, required: true },
			full: { type: Boolean, default: false },
		},
		data() {
			return {
				unescape: unescapeJs,
				showEditStockModal: false,
				currentEditStock: {},
				showNewFilterModal: false,
				showDeleteFilterModal: false,
				newFilterName: "",
				newFilterQueries: {},
				manufacturer: "",
				barcode: "",
				name: "",
				color: "",
				reference: "",
				type: "",
				stocks: "",
				filterEditForm: {
					manufacturer: "",
					barcode: "",
					name: "",
					color: "",
					reference: "",
					type: "",
					stocks: "",
				},
				filterEditShow: false,
			}
		},
		computed: {
			showCreateFilter() {
				if ( this.manufacturer != "" ) {
					return true;
				}
				if ( this.barcode != "" ) {
					return true;
				}
				if ( this.name != "" ) {
					return true;
				}
				if ( this.color != "" ) {
					return true;
				}
				if ( this.reference != "" ) {
					return true;
				}
				if ( this.type != "" ) {
					return true;
				}
				if ( this.stocks != "" ) {
					return true;
				}
				return false;
			}
		},
		methods: {
			prettyBarCode( barcode ) {
				if ( !barcode ) {
					return "";
				}
				return barcode.substring(0,1) + " " + barcode.substring(1,8) + " " + barcode.substring(8,13);
			},
			searchStockMatches(stock) {
				return matches(this, stock)
			},
			// matches( stock ) {
			// 	var set = 0;
			// 	var count = 0;
			// 	[ "manufacturer", "barcode", "name", "color", "reference", "type", "stocks" ].forEach( field => {
			// 		if ( field == "stocks" ) {
			// 			set++;
			// 			if ( this.stocks == '0' ) {
			// 				count += ( stock.stocks <= 0 ? 1 : 0 );
			// 			} else if ( this.stocks == '5' ) {
			// 				count += ( stock.stocks <= 5 ? 1 : 0 );
			// 			} else if ( this.stocks == '10' ) {
			// 				count += ( stock.stocks <= 10 ? 1 : 0 );
			// 			} else {
			// 				count++;
			// 			}
			// 		} else if ( this[field] != null && this[field] != "" ) {
			// 			set++;
			// 			if ( ( stock[field] || "" ).toString().toLowerCase().indexOf( this[field].toLowerCase() ) >= 0 ) {
			// 				count++;
			// 			}
			// 		}
			// 	});
			// 	return count == set;
			// },
			createFilter() {
				if ( this.newFilterName != "" && Object.keys( this.newFilterQueries ).length > 0 ) {
					this.$api.stocksfilters.post( { name: this.newFilterName, filter: JSON.stringify( this.newFilterQueries ) } ).then( response => {
						this.newFilterName = "";
						this.showNewFilterModal = false;
					}).catch( error => console.log( error ) );
				}
			},
			createFilterModal() {
				var queries = {}
				var names = []
				var translation = {
					manufacturer: this.$t( "common.brand" ),
					barcode: this.$t( "stocks.barcode" ),
					name: this.$t( "common.range" ),
					color: this.$t( "stocks.color" ),
					reference: this.$t( "stocks.refcode" ),
					type: this.$t( "stocks.type" ),
					stocks: this.$t( "stocks.stocks" ),
				}
				Object.keys( translation ).forEach( field => {
					if ( field == "stocks" ) {
						if ( this.stocks == '0' ) {
							names.push( /*translation[field] + ": " +*/ this.$t( "stocks.sout" ) );
						} else if ( this.stocks == '5' ) {
							names.push( /*translation[field] + ": " +*/ this.$t( "stocks.fiveless" ) );
						} else if ( this.stocks == '10' ) {
							names.push( /*translation[field] + ": " +*/ this.$t( "stocks.tenless" ) );
						}
					} else if ( this[field] != "" ) {
						names.push( /*translation[field] + ": " +*/ this[field] );
					}
					if ( this[field] != "" ) {
						queries[field] = this[field];
					}
				})
				this.newFilterName = names.join( " + " )
				this.newFilterQueries = queries
				this.showNewFilterModal = true
			},
			editStock( stock ) {
				this.showEditStockModal = true;
				this.currentEditStock = stock || {};
			},
			deleteStock( id ) {
				if ( confirm( 'Voulez-vous vraiment supprimer ce produit ? Cette action est irréversible.' ) ) {
					this.$api.stock.delete( id ).then( response => {
					}).catch( error => console.log( error ) );
				}
			},
			saveEditedFilter() {
				var patch = {
					filter: {}
				};
				Object.keys( this.filterEditForm ).forEach( key => {
					var value = this.filterEditForm[key];
					if ( key == "filterName" ) {
						patch.name = value;
					} else {
						patch.filter[key] = value;
					}
				});
				if ( !patch.name || patch.name.length == 0 ) {
				} else {
					patch.filter = JSON.stringify( patch.filter );
					this.$api.stocksfilter.patch( this.filter.id, patch ).then( response => {
					}).catch( error => console.log( error ) );
				}

				this.filterEditShow = false
			},
			deleteFilter() {
				this.$api.stocksfilter.delete( this.filter.id ).then( response => {
					this.showDeleteFilterModal(true)
				}).catch( error => {
					this.showDeleteFilterModal(false, this.$t("errors." + error.response.data.error))
					console.log( error ) 
				});
			}
		},
		mounted() {
			this.filterEditForm = Object.assign( {}, this.filter.filter );
			this.filterEditForm.filterName = this.filter.name;
		}
	}
</script>
