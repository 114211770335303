export default function matches(filter, stock) {
    let set = 0
    let count = 0
    ;[ 'manufacturer', 'barcode', 'name', 'color', 'reference', 'type', 'stocks' ].forEach( field => {
        if ( field == 'stocks' ) {
            set++
            if ( filter.stocks == '0' ) {
                count += ( stock.stocks <= 0 ? 1 : 0 )
            } else if ( filter.stocks == '5' ) {
                count += ( stock.stocks <= 5 ? 1 : 0 )
            } else if ( filter.stocks == '10' ) {
                count += ( stock.stocks <= 10 ? 1 : 0 )
            } else {
                count++
            }
        } else if ( filter[field] != null && filter[field] != '' ) {
            set++
            if ( ( stock[field] || '' ).toString().toLowerCase().indexOf( filter[field].toLowerCase() ) >= 0 ) {
                count++
            }
        }
    })
    return count == set
}
