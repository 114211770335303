<template>
	<div id="base">
		<h1 class="title-search">{{$t("titles.stockssearch")}}</h1>
		<div class="tab-widget">
			<div class="tab-widget-buttons-wrapper">
				<div class="d-inline-block">
					<button :class="'d-block btn btn-primary tab-widget-button' + ( currIndex == 0 ? ' active' : '' )" v-on:click="currIndex = 0" type="button">
						<span class="anim-underline">Tout</span>
					</button>
				</div>
				<div class="d-inline-block" v-for="( filter, index ) in filters">
					<button :class="'d-block btn btn-primary tab-widget-button' + ( currIndex == ( index + 1 ) ? ' active' : '' )" v-on:click="currIndex = index + 1" type="button">
						<span class="anim-underline">{{ filter.name }}</span>
					</button>
				</div>
			</div>
			<div class="tab-widget-content-wrapper">
				<StockTab v-show="currIndex == 0" :filter="{ filter: {}, stocks: $store.state.stocks }" full ref="tab-0"/>
				<StockTab v-for="( filter, index ) in filters" :key="filter.id" v-show="index + 1 == currIndex" :filter="filter" :ref="'tab-' + ( index + 1 )" class="tab-widget-content"/>
			</div>
		</div>
	</div>
</template>

<script>
	import StockTab from "./stocks/StockTab.vue"
	import stockMatches from "./stocks/stockMatches"

	export default {
		components: {
			StockTab
		},
		data() {
			return {
				currIndex: 0,
				filters : []
			}
		},
		methods: {
			addFilter(filterObject) {
				const stocks = this.$store.state.stocks
				const filter = JSON.parse(filterObject.filter)
				const filterKeys = Object.keys(filter)
				const found = stocks.filter(stock => stockMatches(filter, stock))
				// for ( var j = 0; j < stocks.length; j++ ) {
					// var okForThisOne = false;
					// for ( var k = 0; k < filterKeys.length; k++ ) {
					// 	var type = typeof stocks[j][filterKeys[k]];
					// 	if ( filterKeys[k] == "stocks" ) {
					// 		okForThisOne |= ( parseInt( stocks[j][filterKeys[k]] ) <= parseInt( filter[filterKeys[k]] ) )
					// 	} else if ( type == "string" && filter[filterKeys[k]].length > 0 ) {
					// 		okForThisOne |= ( stocks[j][filterKeys[k]].match( filter[filterKeys[k]] ) !== null );
					// 	}
					// }
					// if ( stockMatches(filter, stock) ) {
					// 	found.push( stocks[j] );
					// }
				// }
				var alreadyExists = null;
				for ( var i = 0; i < this.filters.length; i++ ) {
					if ( this.filters[i].id == filterObject.id ) {
						alreadyExists = this.filters[i];
						break;
					}
				}
				if ( alreadyExists ) {
					this.filters[i].name = filterObject.name;
					this.filters[i].filter = filter;
					this.filters[i].stocks = found;
				} else {
					this.filters.push({
						id: filterObject.id,
						name: filterObject.name,
						filter: filter,
						stocks: found
					});
				}
			},
			deleteFilter( id ) {
				for ( var i = 0; i < this.filters.length; i++ ) {
					if ( this.filters[i].id == id ) {
						this.filters.splice( i, 1 );
						if ( this.currIndex - 1 == i ) {
							this.$nextTick( () => {
								if ( this.currIndex + 1 < this.filters.length ) {
									this.currIndex++;
								} else if ( this.currIndex - 1 >= 0 ) {
									this.currIndex--;
								} else {
									this.currIndex = 0;
								}
								var options = {
									easing: 'ease-in',
									offset: -60,
									force: true,
									x: false,
									y: true
								};
								this.$scrollTo( this.$el, 200, options )
							});
						}
						break;
					}
				}
			},
			update() {
				this.$api.stocksfilters.get().then( response => {
					this.filters = []
					for ( var i = 0; i < response.data.length; i++ ) {
						this.addFilter( response.data[i] )
					}
				}).catch( error => console.log( error ) )
			}
		},
		mounted() {
			// Get all data at start
			this.update()

			// Watch changes
			this.$api.stocksfilters.watch( event => {
				console.log('hi!')
				if ( event.method == "POST" || event.method == "PATCH" ) {
					this.addFilter( event.data[0] )
				} else if ( event.method == "DELETE" ) {
					this.deleteFilter( event.data[0].id )
				}
			})
		}
	}
</script>


<style scoped lang="scss" src="../css/pages/stocks.scss"/>
