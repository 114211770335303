<template>
	<div class="stockscolor">
		<Popper
			class="dropdown d-block"
			trigger="clickToToggle"
			:options="{ placement: 'bottom' }"
			:visible-arrow="false"
			ref="selector"
			@show="$nextTick( () => { /*$refs.searchInput.focus();*/ search( $refs.searchInput.value ) })"
		>
			<div class="dropdown-menu">
				<div
					class="dropdown-choices"
				>
					<button
						v-for="color in stockscolors"
						class="dropdown-item"
						type="button"
						@click="$emit( 'selected', color ); $refs.searchInput.value = color; $emit( 'input', color ); search( '' ); $refs.selector.doClose()"
					>
						{{ color }}
					</button>
					<button
						v-for="color in customcolors"
						:class="'dropdown-item' + ( ( editable || editable === undefined ) ? ' custom' : '' )"
						type="button"
					>
						<span @click="$emit( 'selected', color.name ); $refs.searchInput.value = color.name; $emit( 'input', color.name ); search( '' ); $refs.selector.doClose()">{{ color.name }}</span>
						<StatusButton
							v-if="editable || editable === undefined"
							class="custom-delete"
							type="quaternary small"
							alert="modal"
							@click="deleteCustomColor( color.id, $event )"
						>
							<i class="fa fa-times"></i>
						</StatusButton>
					</button>
					<StatusButton
						v-if="( editable || editable === undefined ) && $refs.searchInput && $refs.searchInput.value && $refs.searchInput.value.length > 0"
						class="dropdown-item"
						type="quaternary"
						alert="modal"
						@click="createCustomColor"
					>
						{{ $t('modals.editstock.addcolor') }}
					</StatusButton>
				</div>
			</div>
			<div slot="reference" >
				<input ref="searchInput" class="dropdown-searchbox form-control" type="text" :value="value" @input="search( $event.target.value ); $emit( 'input', $event.target.value )">
				<button
					id="category"
					aria-expanded="false"
					aria-haspopup="true"
					class="btn btn-secondary dropdown-toggle"
					data-flip="false"
					data-toggle="dropdown"
					name="btn-category"
					type="button"
				>
				</button>
			</div>
		</Popper>
	</div>
</template>

<script>
	import Popper from 'vue-popperjs'
	import StatusButton from './StatusButton.vue'

	export default {
		components: {
			Popper,
			StatusButton
		},
		props: [
			"value",
			"editable"
		],
		data() {
			return {
				stockscolors: this.$t('data.stockscolors'),
				customcolors: this.$store.state.stockscolors
			}
		},
		methods: {
			search( filter ) {
				let found = []
				filter = filter.toLowerCase()
				for ( var i = 0; i < this.$t('data.stockscolors').length; i++ ) {
					let color = this.$t('data.stockscolors')[i]
					if ( color.toLowerCase().indexOf( filter ) >= 0 ) {
						found.push( color )
					}
				}
				this.stockscolors = found
				found = []
				for ( var i = 0; i < this.$store.state.stockscolors.length; i++ ) {
					let color = this.$store.state.stockscolors[i]
					if ( color.name.toLowerCase().indexOf( filter ) >= 0 ) {
						found.push( color )
					}
				}
				this.customcolors = found
			},
			createCustomColor( callback ) {
				if ( this.$refs.searchInput.value && this.$refs.searchInput.value.length > 0 ) {
					this.$api.stockscolors.post( { name: this.$refs.searchInput.value } ).then( response => {
						callback( true )
						this.search( this.$refs.searchInput.value )
					}).catch( error => {
						console.log( error )
						callback( false, error.response.data.error || error )
					})
				}
			},
			deleteCustomColor( id, callback ) {
				this.$api.stockscolor.delete( id ).then( response => {
					callback( true )
					setTimeout( () => {
						this.$refs.searchInput.value = ""
						this.search( "" )
					}, 750 )
				}).catch( error => {
					console.log( error )
					callback( false, error.response.data.error || error )
				})
			}
		},
		mounted() {
		},
		beforeDestroy() {
		}
	}
</script>
