<template>
	<div class="stockstype">
		<Popper
			class="dropdown d-block"
			trigger="clickToToggle"
			:options="{ placement: 'bottom' }"
			:visible-arrow="false"
			ref="selector"
			@show="$nextTick( () => { /*$refs.searchInput.focus()*/; search( $refs.searchInput.value ) })"
		>
			<div class="dropdown-menu">
				<div
					class="dropdown-choices"
				>
					<button
						v-for="type in stockstypes"
						class="dropdown-item"
						type="button"
						@click="$emit( 'selected', type ); $refs.searchInput.value = type; $emit( 'input', type ); search( '' ); $refs.selector.doClose()"
					>
						{{ type }}
					</button>
					<button
						v-for="type in customtypes"
						:class="'dropdown-item' + ( ( editable || editable === undefined ) ? ' custom' : '' )"
						type="button"
					>
						<span @click="$emit( 'selected', type.name ); $refs.searchInput.value = type.name; $emit( 'input', type.name ); search( '' ); $refs.selector.doClose()">{{ type.name }}</span>
						<StatusButton
							v-if="editable || editable === undefined"
							class="custom-delete"
							type="quaternary small"
							alert="modal"
							@click="deleteCustomType( type.id, $event )"
						>
							<i class="fa fa-times"></i>
						</StatusButton>
					</button>
					<StatusButton
						v-if="( editable || editable === undefined ) && $refs.searchInput && $refs.searchInput.value && $refs.searchInput.value.length > 0"
						class="dropdown-item"
						type="quaternary"
						alert="modal"
						@click="createCustomType"
					>
						{{ $t('modals.editstock.addtype') }}
					</StatusButton>
				</div>
			</div>
			<div slot="reference" >
				<input ref="searchInput" class="dropdown-searchbox form-control" type="text" :value="value" @input="search( $event.target.value ); $emit( 'input', $event.target.value )">
				<button
					id="category"
					aria-expanded="false"
					aria-haspopup="true"
					class="btn btn-secondary dropdown-toggle"
					data-flip="false"
					data-toggle="dropdown"
					name="btn-category"
					type="button"
				>
				</button>
			</div>
		</Popper>
	</div>
</template>

<script>
	import Popper from 'vue-popperjs'
	import StatusButton from './StatusButton.vue'

	export default {
		components: {
			Popper,
			StatusButton
		},
		props: [
			"value",
			"editable"
		],
		data() {
			return {
				stockstypes: this.$t('data.stockstypes'),
				customtypes: this.$store.state.stockstypes
			}
		},
		methods: {
			search( filter ) {
				let found = []
				filter = filter.toLowerCase()
				for ( var i = 0; i < this.$t('data.stockstypes').length; i++ ) {
					let type = this.$t('data.stockstypes')[i]
					if ( type.toLowerCase().indexOf( filter ) >= 0 ) {
						found.push( type )
					}
				}
				this.stockstypes = found
				found = []
				for ( var i = 0; i < this.$store.state.stockstypes.length; i++ ) {
					let type = this.$store.state.stockstypes[i]
					if ( type.name.toLowerCase().indexOf( filter ) >= 0 ) {
						found.push( type )
					}
				}
				this.customtypes = found
			},
			createCustomType( callback ) {
				if ( this.$refs.searchInput.value && this.$refs.searchInput.value.length > 0 ) {
					this.$api.stockstypes.post( { name: this.$refs.searchInput.value } ).then( response => {
						callback( true )
						this.search( this.$refs.searchInput.value )
					}).catch( error => {
						console.log( error )
						callback( false, error.response.data.error || error )
					})
				}
			},
			deleteCustomType( id, callback ) {
				this.$api.stockstype.delete( id ).then( response => {
					callback( true )
					setTimeout( () => {
						this.$refs.searchInput.value = ""
						this.search( "" )
					}, 750 )
				}).catch( error => {
					console.log( error )
					callback( false, error.response.data.error || error )
				})
			}
		},
		mounted() {
		},
		beforeDestroy() {
		}
	}
</script>
