<template>
	<Modal name="edit-stock">
		<template v-slot:header>
			<h1 class="title-search">{{$t("stocks.stock")}}</h1>
		</template>
		<template v-slot:content>
			<div class="modal-content">
				<form class="form" @submit="$event.preventDefault() ">
					<div class="input-group form-group">
						<div class="input-group-prepend">
							<span class="input-group-text">{{$t("stocks.barcode")}}</span>
						</div>
						
						<input class="form-control form-control" name="barcode" type="text" v-model="barcode">
					</div>
					<div class="input-group form-group">
						<div class="input-group-prepend">
							<span class="input-group-text">{{$t("common.type")}}</span>
						</div>
						<StockTypeButton v-model="type"/>
						<!--input class="form-control form-control" name="type" type="text" v-model="type"-->
					</div>
					<div class="input-group form-group">
						<div class="input-group-prepend">
							<span class="input-group-text">{{$t("stocks.refcode")}}</span>
						</div>
						<input class="form-control form-control" name="reference" type="text" v-model="reference">
					</div>
					<div :class="'input-group form-group' + ( missing.manufacturer ? ' required' : '' )">
						<div class="input-group-prepend">
							<span class="input-group-text">{{$t("common.brand")}}</span>
						</div>
						<input autocomplete="on" class="form-control form-control" name="manufacturer" required="" type="text" v-model="manufacturer">
					</div>
					<div :class="'input-group form-group' + ( missing.name ? ' required' : '' )">
						<div class="input-group-prepend">
							<span class="input-group-text">{{$t("stocks.range")}}</span>
						</div>
						<input class="form-control form-control" name="name" required="" type="text" v-model="name">
					</div>
					<div :class="'input-group form-group' + ( missing.color ? ' required' : '' )">
						<div class="input-group-prepend">
							<span class="input-group-text">{{$t("stocks.color")}}</span>
						</div>
						<StockColorButton v-model="color"/>
						<!--input class="form-control form-control" name="color" required="" type="text" v-model="color"-->
					</div>
					<div class="input-group form-group">
						<div class="input-group-prepend">
							<span class="input-group-text">{{$t("common.descript")}}</span>
						</div>
						<input class="form-control form-control" name="description" type="text" v-model="description">
					</div>
					<div class="input-group form-group">
						<div class="input-group-prepend">
							<span class="input-group-text">{{$t("common.price")}}</span>
						</div>
						<input id="price" class="form-control form-control" name="price" type="text" @keypress="validateFloat" v-model="price">
					</div>
					<div :class="'input-group form-group' + ( missing.stocks ? ' required' : '' )">
						<div class="input-group-prepend">
							<span class="input-group-text">{{$t("stocks.stock")}}</span>
						</div>
						<input id="stocks-input" class="form-control form-control" name="stocks" type="text" @keypress="validateInteger" v-model="stocks">
					</div>
				</form>
				<div class="bottles">
					<div class="bottle" v-for="i in current.length">
						<VueSlider tooltip="none" direction="btt" class="vertical" v-model="current[i - 1]" :data="[0,10,20,30,40,50,60,70,80,90,100]" :marks="true"/>
						<div class="value">{{ current[i - 1] }}</div>
						<div class="bottle-mask"/>
					</div>
					<button v-show="current.length < 3" class="btn plus" @click="current.length < 3 ? current.push(100) : false">
						<i class="fa fa-plus"></i>
					</button>
				</div>
			</div>
		</template>
		<template v-slot:buttons>
			<StatusButton v-if="stock && Object.keys(stock).length > 0" id="delete" type="danger" alert="modal" @click="showDeleteConfirm = $event"><i class="fas fa-trash-alt"></i></StatusButton>
			<StatusButton :enabled="!deleting" id="validate" type="primary" alert="modal" @click="save( $event, true )"><i class="fas fa-check"></i></StatusButton>
			<StatusButton class="desktop" :enabled="!deleting" ref="apply" id="apply" type="secondary" alert="modal" @click="save( $event, false )">{{$t("common.apply")}}</StatusButton>
			<button id="cancel" class="btn btn-gray" @click="$emit('close')" type="button">{{ $t("common.close") }}</button>
			<Confirm v-if="showDeleteConfirm" ref="deleteConfirm">
				{{ $t('pack.confirmdelete') }}
			</Confirm>
		</template>
	</Modal>
</template>

<script>
	import VueSlider from 'vue-slider-component'
	import Modal from '../components/Modal.vue'
	import Confirm from "../modals/Confirm.vue"
	import StatusButton from '../components/StatusButton.vue'
	import StockTypeButton from '../components/StockTypeButton.vue'
	import StockColorButton from '../components/StockColorButton.vue'

	export default {
		components: {
			VueSlider,
			Modal,
			StatusButton,
			StockTypeButton,
			StockColorButton,
			Confirm
		},
		props: [
			"data"
		],
		data() {
			return {
				stock: {
					id: this.data.id || null,
					barcode: this.data.barcode || "",
					type: this.data.type || "",
					reference: this.data.reference || "",
					manufacturer: this.data.manufacturer || "",
					name: this.data.name || "",
					color: this.data.color || "",
					description: this.data.description || "",
					price: this.data.price || "",
					stocks: ( isNaN(this.data.stocks) ? 0 : this.data.stocks ),
					current: ( this.data.current || [] ).slice()
				},
				showDeleteConfirm: false,
				barcode: "",
				type: "",
				reference: "",
				manufacturer: "",
				description: "",
				name: "",
				color: "",
				price: "",
				stocks: "",
				current: [],
				missing: {},
				deleting: false
			}
		},
		watch: {
			showDeleteConfirm( callback ) {
				if ( callback !== false ) {
					this.$nextTick( () => {
						this.$refs.deleteConfirm.$on( "confirm", () => {
							this.deleting = true
							this.$refs.deleteConfirm.$off( 'confirm' )
							this.$refs.deleteConfirm.$off( 'close' )
							this.showDeleteConfirm = false
							this.deleteStock( callback )
						});
						this.$refs.deleteConfirm.$on( "close", () => {
							this.$refs.deleteConfirm.$off( 'confirm' )
							this.$refs.deleteConfirm.$off( 'close' )
							this.showDeleteConfirm = false;
							callback( -1 );
						});
					});
				}
			}
		},
		methods: {
			validateInteger( event ) {
				let key = null

				if ( event.type === 'paste' ) {
					key = event.clipboardData.getData('text/plain')
				} else {
					key = String.fromCharCode( event.keyCode || event.which )
				}
				if ( !/[0-9]/.test(key) ) {
					event.returnValue = false
					if ( event.preventDefault ) {
						event.preventDefault()
					}
				}
			},
			validateFloat( event ) {
				let key = null

				if ( event.type === 'paste' ) {
					key = event.clipboardData.getData('text/plain')
				} else {
					key = String.fromCharCode( event.keyCode || event.which )
				}
				const value = event.target.value.substr( 0, event.target.selectionStart ) + key + event.target.value.substr( event.target.selectionEnd )
				if ( value.length > 0 && ( !/[0-9]|\./.test(key) || !value.match(/^([0-9]+)(\.)?([0-9]+)?$/) ) ) {
					event.returnValue = false
					if ( event.preventDefault ) {
						event.preventDefault()
					}
				}
			},
			save( callback, closeAfter ) {
				let keys = [
					"barcode",
					"type",
					"reference",
					"manufacturer",
					"description",
					"name",
					"color",
					"price",
					"stocks",
					"current",
				]
				let mandatory = [
					"manufacturer",
					"name",
					"color",
					"stocks"
				]

				var isNew = ( !this.stock || isNaN( this.stock.id ) || this.stock.id <= 0 )
				console.log( "isNew", isNew, !this.stock, this.stock )

				var values = {}
				keys.forEach( key => {
					let copy = ( isNew && this[key].length > 0 )
					if ( !isNew && this[key] !== undefined && ( ( !Array.isArray(this[key]) && this[key] != this.stock[key] ) || ( Array.isArray(this[key]) && !this[key].isSame(this.stock[key]) ) ) ) {
						console.log( "different", isNew, key, this[key], this.stock[key] )
						copy = true
					}
					if ( copy ) {
						if ( key === "price" ) {
							values[key] = parseFloat( this[key] )
						} else {
							values[key] = this[key]
						}
					}
				})

				if ( isNaN( values.price ) ) {
					delete values.price
				}

				this.missing = {}
				for ( let i = 0; i < mandatory.length; i++ ) {
					if ( !this[mandatory[i]] || this[mandatory[i]].length == 0 ) {
						this.missing[mandatory[i]] = true
					}
				}
				if ( Object.keys( this.missing ).length > 0 ) {
					callback( false )
					return
				}

				if ( values.current ) {
					values.current = values.current.filter( v => v > 0 && v < 100 )
				}

				console.log( "values", values )

				if ( isNew ) {
					this.$api.stocks.post( values ).then( response => {
						callback( true )
						this.stock = response.data[0]
						this.stock.barcode = this.stock.barcode || ""
						this.stock.type = this.stock.type || ""
						this.stock.reference = this.stock.reference || ""
						this.stock.manufacturer = this.stock.manufacturer || ""
						this.stock.name = this.stock.name || ""
						this.stock.color = this.stock.color || ""
						this.stock.description = this.stock.description || ""
						this.stock.price = this.stock.price || ""
						this.stock.stocks = ( isNaN(this.stock.stocks) ? 0 : this.stock.stocks )
						this.stock.current = ( this.stock.current || [] ).slice()
						if ( closeAfter ) {
							setTimeout( () => {
								this.$emit( 'close' )
							}, 1250 )
						}
					}).catch( error => {
						console.log( error )
						callback( false, error.response.data.error )
					})
				} else {
					if ( this.stock.id > 0 && Object.keys( values ).length > 0 ) {
						this.$api.stock.patch( this.stock.id, values ).then( response => {
							this.stock = Object.assign( this.stock, values )
							if ( values.current ) {
								this.current = values.current.slice()
							}
							callback( true )
							if ( closeAfter ) {
								setTimeout( () => {
									this.$emit( 'close' )
								}, 1250 )
							}
						}).catch( error => {
							console.log( error )
							callback( false, error.response.data.error )
						})
					} else {
						callback( -1 )
						if ( closeAfter ) {
							this.$emit( 'close' )
						}
					}
				}
			},
			deleteStock( callback ) {
				this.$api.stock.delete( this.stock.id ).then( response => {
					callback( true )
					setTimeout( () => {
						this.$emit( 'close' )
					}, 1250 )
				}).catch( error => {
					console.log( error )
					callback( false, error.response.data.error )
				})
			}
		},
		mounted() {
			this.barcode = this.data.barcode || ""
			this.type = this.data.type || ""
			this.reference = this.data.reference || ""
			this.manufacturer = this.data.manufacturer || ""
			this.name = this.data.name || ""
			this.color = this.data.color || ""
			this.description = this.data.description || ""
			this.price = this.data.price || ""
			this.stocks = ( isNaN(this.data.stocks) ? 0 : this.data.stocks ),
			this.current = ( this.data.current || [] ).slice()
		}
	}
</script>

<style scoped lang="scss" src="../css/modals/editstock.scss"/>

